<script setup>
// import menuJSON from 'assets/data/menu-main.json'
import {inject} from 'vue'
import {useLocalePath} from 'vue-i18n-routing'

const processedDataMenu = inject('processedDataMenu')
const localePath = useLocalePath()
</script>

<template>
  <nav class="menu-footer-main">
    <ul class="menu-list">
      <li
        v-for="(item, index) in processedDataMenu?.menu_items"
        :key="index"
        class="menu-item menu-item--level-0">
        <div class="menu-nolink" @click="openSubmenu">
          <div class="menu-nolink-label">{{ item.label }}</div>
        </div>
        <ul class="menu-list">
          <li
            v-for="(subitem, index) in item.submenu"
            :key="index"
            class="menu-item menu-item--level-1">
            <NuxtLink :to="localePath(subitem.path)" class="menu-link sensible"
              >{{ subitem.label }}
            </NuxtLink>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
.menu-footer-main {
  .menu-list {
    @include list-reset;
  }

  > .menu-list {
    display: flex;
    justify-content: space-between;

    @include mq(lg) {
      display: flex;
      justify-content: space-between;
    }

    > .menu-item {
      flex: 1;
      margin-left: pxrem(10);
      margin-right: pxrem(10);

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .menu-nolink-label {
    @include paragraph($color-white);
    margin-bottom: pxrem(8);
  }

  .menu-link {
    @include paragraph($color-white);
    font-size: $font-small;
    opacity: 0.6;
    text-decoration: none;
    transition: opacity $transition-default;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
