// @ts-nocheck


export const localeCodes = [
  "de",
  "en",
  "it",
  "zh"
]

export const localeMessages = { 
  "de": [
      { key: "../locales/de-DE.json", load: () => import("../locales/de-DE.json" /* webpackChunkName: "locale__home_forge_pininfarina_it_locales_de_DE_json" */), cache: true },
  ],
  "en": [
      { key: "../locales/en-US.json", load: () => import("../locales/en-US.json" /* webpackChunkName: "locale__home_forge_pininfarina_it_locales_en_US_json" */), cache: true },
  ],
  "it": [
      { key: "../locales/it-IT.json", load: () => import("../locales/it-IT.json" /* webpackChunkName: "locale__home_forge_pininfarina_it_locales_it_IT_json" */), cache: true },
  ],
  "zh": [
      { key: "../locales/zh-CN.json", load: () => import("../locales/zh-CN.json" /* webpackChunkName: "locale__home_forge_pininfarina_it_locales_zh_CN_json" */), cache: true },
  ],
}

export const resolveNuxtI18nOptions = async (context) => {
  const nuxtI18nOptions = {
  "experimental": {
    "jsTsFormatResource": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "de",
      "iso": "de-DE",
      "name": "Deutsch",
      "files": [
        "locales/de-DE.json"
      ]
    },
    {
      "code": "en",
      "iso": "en-US",
      "name": "English (US)",
      "files": [
        "locales/en-US.json"
      ]
    },
    {
      "code": "it",
      "iso": "it-IT",
      "name": "Italiano",
      "files": [
        "locales/it-IT.json"
      ]
    },
    {
      "code": "zh",
      "iso": "zh-CN",
      "name": "中国人",
      "files": [
        "locales/zh-CN.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_and_default",
  "lazy": true,
  "langDir": "locales/",
  "rootRedirect": null,
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "about/index": {
      "en": "/about/",
      "de": "/um/",
      "zh": "/guanyu/",
      "it": "/about/"
    },
    "about/academy": {
      "en": "/about/pininfarina-academy",
      "de": "/um/pininfarina-academy",
      "zh": "/guanyu/pininfarina-academy",
      "it": "/about/pininfarina-academy"
    },
    "about/awards": {
      "en": "/about/awards",
      "de": "/um/auszeichnungen",
      "zh": "/guanyu/jiangxiang",
      "it": "/about/premi"
    },
    "about/certification": {
      "en": "/about/certification",
      "de": "/um/renzheng",
      "zh": "/guanyu/jiangxiang",
      "it": "/about/certificazioni"
    },
    "about/heritage": {
      "en": "/about/heritage",
      "de": "/um/erbe",
      "zh": "/guanyu/yichan",
      "it": "/about/heritage"
    },
    "about/our-group": {
      "en": "/about/our-group",
      "de": "/um/unsere-gruppe",
      "zh": "/guanyu/women-de-zu",
      "it": "/about/il-gruppo"
    },
    "about/sustainability": {
      "en": "/about/sustainability",
      "de": "/um/nachhaltigkeit",
      "zh": "/guanyu/ke-chixu-xing",
      "it": "/about/sostenibilita"
    },
    "about/team": {
      "en": "/about/team",
      "de": "/um/team",
      "zh": "/guanyu/tuandui",
      "it": "/about/team"
    },
    "about/visit-pininfarina": {
      "en": "/about/visit-pininfarina",
      "de": "/um/besuchen-sie-pininfarina",
      "zh": "/guanyu/hui-pininfarina",
      "it": "/about/visita-pininfarina"
    },
    "careers/index": {
      "en": "/careers/",
      "de": "/karriere/",
      "zh": "/zhiye-shengya/",
      "it": "/carriere/"
    },
    "careers/[jobSlug]": {
      "en": "/careers/:jobSlug",
      "de": "/karriere/:jobSlug",
      "zh": "/zhiye-shengya/:jobSlug",
      "it": "/carriere/:jobSlug"
    },
    "cookie-policy": {
      "en": "/cookie-policy/",
      "de": "/cookie-policy/",
      "zh": "/cookie-policy/",
      "it": "/cookie-policy/"
    },
    "classiche/index": {
      "en": "/classiche/",
      "de": "/classiche/",
      "zh": "/classiche/",
      "it": "/classiche/"
    },
    "contacts/index": {
      "en": "/contacts/",
      "de": "/kontakte/",
      "zh": "/lianxi-ren/",
      "it": "/contatti/"
    },
    "contacts/our-offices": {
      "en": "/contacts/our-offices",
      "de": "/kontakte/unsere-gruppe",
      "zh": "/lianxi-ren/women-de-zu",
      "it": "/contatti/i-nostri-uffici"
    },
    "contacts/[countrySlug]": {
      "en": "/contacts/:countrySlug",
      "de": "/kontakte/:countrySlug",
      "zh": "/lianxi-ren/:countrySlug",
      "it": "/contatti/:countrySlug"
    },
    "contacts/messageSent": {
      "en": "/message-sent",
      "de": "/message-sent",
      "zh": "/message-sent",
      "it": "/message-sent"
    },
    "investor-relations": {
      "en": "/investor-relations/quotation-and-corporate-bodies",
      "de": "/investor-relations/quotation-and-corporate-bodies",
      "zh": "/investor-relations/quotation-and-corporate-bodies",
      "it": "/investor-relations/quotazione-e-organi-societari"
    },
    "investor-relations/[investorSlug]": {
      "en": "/investor-relations/:investorSlug",
      "de": "/investor-relations/:investorSlug",
      "zh": "/investor-relations/:investorSlug",
      "it": "/investor-relations/:investorSlug"
    },
    "news/index": {
      "en": "/news/",
      "de": "/nachrichten/",
      "zh": "/xinwen/",
      "it": "/notizie/"
    },
    "media-hub/index": {
      "en": "/media-hub/",
      "de": "/media-hub/",
      "zh": "/media-hub/",
      "it": "/media-hub/"
    },
    "media-hub/login": {
      "en": "/media-hub/login",
      "de": "/media-hub/login",
      "zh": "/media-hub/login",
      "it": "/media-hub/login"
    },
    "media-hub/hq-resources": {
      "en": "/media-hub/hq-resources",
      "de": "/media-hub/hq-resources",
      "zh": "/media-hub/hq-resources",
      "it": "/media-hub/hq-resources"
    },
    "media-hub/password-recovery": {
      "en": "/media-hub/password-recovery",
      "de": "/media-hub/password-recovery",
      "zh": "/media-hub/password-recovery",
      "it": "/media-hub/password-recovery"
    },
    "media-hub/register": {
      "en": "/media-hub/register",
      "de": "/media-hub/register",
      "zh": "/media-hub/register",
      "it": "/media-hub/register"
    },
    "news/[newsSlug]": {
      "en": "/news/:newsSlug",
      "de": "/nachrichten/:newsSlug",
      "zh": "/xinwen/:newsSlug",
      "it": "/notizie/:newsSlug"
    },
    "privacy-policy": {
      "en": "/privacy-policy/",
      "de": "/privacy-policy/",
      "zh": "/privacy-policy/",
      "it": "/privacy-policy/"
    },
    "projects/index": {
      "en": "/projects/",
      "de": "/projekte/",
      "zh": "/xiangmu/",
      "it": "/progetti/"
    },
    "projects/[projectSlug]": {
      "en": "/projects/:projectSlug",
      "de": "/projekte/:projectSlug",
      "zh": "/xiangmu/:projectSlug",
      "it": "/progetti/:projectSlug"
    },
    "sectors/index": {
      "en": "/sectors/",
      "de": "/sektoren/",
      "zh": "/bumen/",
      "it": "/settori/"
    },
    "sectors/[sectorSlug]/index": {
      "en": "/sectors/:sectorSlug/",
      "de": "/sektoren/:sectorSlug/",
      "zh": "/bumen/:sectorSlug/",
      "it": "/settori/:sectorSlug/"
    },
    "sectors/[sectorSlug]/portfolio": {
      "en": "/sectors/:sectorSlug/portfolio",
      "de": "/sektoren/:sectorSlug/portfolio",
      "zh": "/bumen/:sectorSlug/wenjian-jia",
      "it": "/settori/:sectorSlug/portfolio"
    },
    "services/index": {
      "en": "/services/",
      "de": "/dienstleistungen/",
      "zh": "/fuwu/",
      "it": "/servizi/"
    },
    "services/[serviceSlug]": {
      "en": "/services/:serviceSlug",
      "de": "/dienstleistungen/:serviceSlug",
      "zh": "/fuwu/:serviceSlug",
      "it": "/servizi/:serviceSlug"
    },
    "search/index": {
      "en": "/search/",
      "de": "/suchen/",
      "zh": "/sousuo/",
      "it": "/ricerca/"
    },
    "whistleblowing/index": {
      "en": "/whistleblowing/",
      "de": "/whistleblowing/",
      "zh": "/whistleblowing/",
      "it": "/whistleblowing/"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "config": {
    "fallbackLocale": "en"
  },
  "i18nModules": []
}
  
  const vueI18nConfigLoader = async loader => {
    const config = await loader().then(r => r.default || r)
    if (typeof config === 'object') return config
    if (typeof config === 'function') return await config()
    return {}
  }

  const deepCopy = (src, des, predicate) => {
    for (const key in src) {
      if (typeof src[key] === 'object') {
        if (!(typeof des[key] === 'object')) des[key] = {}
        deepCopy(src[key], des[key], predicate)
      } else {
        if (predicate) {
          if (predicate(src[key], des[key])) {
            des[key] = src[key]
          }
        } else {
          des[key] = src[key]
        }
      }
    }
  }
  
  const mergeVueI18nConfigs = async (loader) => {
    const layerConfig = await vueI18nConfigLoader(loader)
    const cfg = layerConfig || {}
    
    for (const [k, v] of Object.entries(cfg)) {
      if(nuxtI18nOptions.vueI18n?.[k] === undefined || typeof nuxtI18nOptions.vueI18n?.[k] !== 'object') {
        nuxtI18nOptions.vueI18n[k] = v
      } else {
        deepCopy(v, nuxtI18nOptions.vueI18n[k])
      }
    }
  }

  nuxtI18nOptions.vueI18n = { messages: {} }
  
  await mergeVueI18nConfigs(() => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: i18n_config_bffaebcb */)) 
    
  return nuxtI18nOptions
}

export const nuxtI18nOptionsDefault = {
  "experimental": {
    "jsTsFormatResource": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const nuxtI18nInternalOptions = {
  "__normalizedLocales": [
    {
      "code": "de",
      "iso": "de-DE",
      "name": "Deutsch",
      "files": [
        {
          "path": "locales/de-DE.json"
        }
      ]
    },
    {
      "code": "en",
      "iso": "en-US",
      "name": "English (US)",
      "files": [
        {
          "path": "locales/en-US.json"
        }
      ]
    },
    {
      "code": "it",
      "iso": "it-IT",
      "name": "Italiano",
      "files": [
        {
          "path": "locales/it-IT.json"
        }
      ]
    },
    {
      "code": "zh",
      "iso": "zh-CN",
      "name": "中国人",
      "files": [
        {
          "path": "locales/zh-CN.json"
        }
      ]
    }
  ]
}
 
export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = true
