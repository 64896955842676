export const ENDPOINT_PARDOT_URL =
  'https://go.pininfarina.it/l/880752/2023-11-14/yywgb'

export const IUBENDA = {
  en: '64015471',
  it: '91070589',
  de: '64775889',
}

// sector mapping
const sectorMappings = [
  {
    sectorName: 'automotive',
    translations: {
      en: 'automotive',
      it: 'automotive',
      de: 'automotive',
      zh: 'automotive',
    },
    filename: 'automotive',
  },
  {
    sectorName: 'architecture',
    translations: {
      en: 'architecture',
      it: 'architecture',
      de: 'architecture',
      zh: 'architecture',
    },
    filename: 'architecture',
  },
  {
    sectorName: 'mobility-transportation',
    translations: {
      en: 'mobility-transportation',
      it: 'mobility-transportation',
      de: 'mobility-transportation',
      zh: 'mobility-transportation',
    },
    filename: 'mobility',
  },
  {
    sectorName: 'nautical',
    translations: {
      en: 'nautical',
      it: 'nautical',
      de: 'nautical',
      zh: 'nautical',
    },
    filename: 'nautical',
  },
  {
    sectorName: 'product-design',
    translations: {
      en: 'product-design',
      it: 'product-design',
      de: 'produkte-design',
      zh: 'produkte-design',
    },
    filename: 'product',
  },
  {
    sectorName: 'wind-tunnel',
    translations: {
      en: 'wind-tunnel',
      it: 'wind-tunnel',
      de: 'wind-tunnel',
      zh: 'wind-tunnel',
    },
    filename: 'wind',
  },
]
// Function to get the filename given a sector name and language
// used for p5.js pattern
export function getSectorFileName(sectorName, language) {
  const sectorMapping = sectorMappings.find(
    (mapping) => mapping.sectorName === sectorName
  )
  if (sectorMapping && sectorMapping.translations[language]) {
    return sectorMapping.filename
  }
  // Return a default value or handle the case where the mapping doesn't exist
  return null
}

// Function to obtain endpoint name from slug
export function getEndpointFromSlug(elementsTranslations, actualSlug, locale) {
  const translationItem = elementsTranslations.find(item => {
    return Object.values(item.translations).includes(actualSlug) || item.endpoint === actualSlug
  })

  if( translationItem ){
    return translationItem.translations[locale] || translationItem.endpoint
  }

  // console.error(`No matching found for the current slug: ${actualSlug}`)
  return actualSlug // Returning original slug if no translations are available
}

// architecture filters map
const sectorAreasMapping = new Map()
sectorAreasMapping.set('interior', 'interior')
sectorAreasMapping.set('architecture', 'architecture')
sectorAreasMapping.set('infrastructure', 'infrastructure-transport')
sectorAreasMapping.set('urban', 'urban-design')
sectorAreasMapping.set('brand-licensing', 'brand-licensing')
sectorAreasMapping.set('performance-apparel', 'performance-apparel')


export function getSectorAreaFilter(area) {
  return sectorAreasMapping.get(area)
}

// about mapping
const aboutFirstPage = {
  en: '/about/our-group',
  de: '/de/um/unsere-gruppe',
  zh: '/zh/guanyu/women-de-zu',
  it: '/it/about/il-gruppo',
}

// contacts mapping
const contactsFirstPage = {
  en: '/contacts/our-offices',
  de: '/de/kontakte/unsere-gruppe',
  zh: '/zh/lianxi-ren/women-de-zu',
  it: '/it/contatti/i-nostri-uffici',
}

// methodology mapping
const methodologyFirstPage = {
  en: '/approach/methodology',
  de: '/de/approach/methodology',
  zh: '/zh/approach/methodology',
  it: '/it/approach/methodology',
}

// investor relations mapping
const investorRelationsFirstPage = {
  en: '/investor-relations/quotation-and-corporate-bodies/',
  it: '/it/investor-relations/quotazione-e-organi-societari/',
  de: '/de/investor-relations/quotation-and-corporate-bodies/',
  zh: '/zh/investor-relations/quotation-and-corporate-bodies/',
}

// sectors mapping
const sectorsFirstPage = {
  en: '/sectors/automotive',
  de: '/de/sektoren/automotive',
  zh: '/zh/bumen/automotive',
  it: '/it/settori/automotive',
}

// services mapping
const servicesFirstPage = {
  en: '/services/brand-design',
  de: '/de/dienstleistungen/brand-design',
  zh: '/zh/fuwu/brand-design',
  it: '/it/servizi/brand-design',
}

export function getPageFallback(pageType, lang) {
  if( pageType == 'about') return aboutFirstPage[lang]
  if( pageType == 'contacts') return contactsFirstPage[lang]
  if( pageType == 'investor-relations') return investorRelationsFirstPage[lang]
  if( pageType == 'methodology') return methodologyFirstPage[lang]
  if( pageType == 'sectors') return sectorsFirstPage[lang]
  if( pageType == 'services') return servicesFirstPage [lang]
}