<script setup>
import {provide} from 'vue'
const props = defineProps({
  error: Object,
})

const error = useError()

// parsing menu for error pages
const config = useRuntimeConfig()
const {locale} = useI18n()
const parser = ParserUtils()

let endpointMenu = config.public.apiBase + locale.value + '/menu.json'
// console.log('ENDPOINT MENU: ', endpointMenu)
const {fetchedData: fetchedDataMenu} = await useDataStatic(endpointMenu)
const processedDataMenu = computed(() => {
  if( fetchedDataMenu.value ){
    return parser.mainMenu(fetchedDataMenu.value)
  }
  return null
})
provide('processedDataMenu', processedDataMenu)

const titleHead =
  error.value.statusCode === 404 ? '404 - Page not found' : 'Error'
const classHead = error.value.statusCode === 404 ? 'page--404' : 'page--500'

useHead({
  title: titleHead,
  bodyAttrs: {
    class: 'page ' + classHead,
  },
})
</script>

<template>
  <Html>
    <div ref="layout" class="layout-container">
      <SharedCursor />
      <header class="header">
        <div class="header-wrapper">
          <div class="header-logo-wrapper">
            <NuxtLink :to="localePath('/')" class="sensible">
              <svg class="icon icon--logo icon--white-fill">
                <use xlink:href="#logo"></use>
              </svg>
            </NuxtLink>
          </div>
        </div>
      </header>
      <Error404 v-if="error && error.statusCode === 404" />
      <Error500 v-if="error && error.statusCode === 500" />
      <SharedFooter />
      <SharedSvgSymbols />
    </div>
  </Html>
</template>

<style lang="scss">
.header {
  backdrop-filter: blur(4px);
  background-color: rgba($color-blue, 0.8);
  height: $header-height;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateY(0);
  transition: background-color $transition-default, transform ease-out 250ms;
  z-index: 3;

  &.hidden {
    transform: translateY(-100%);
  }

  &.top-position {
    background-color: transparent;
  }

  .header-wrapper {
    @include make-col-ready();
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;

    @include mq(md) {
      @include make-col(22);
      @include make-col-offset(1);
    }
  }

  .header-logo-wrapper {
    margin-right: pxrem(40);

    @include mq(md) {
      @include make-col(8);
      margin-right: 0;
    }
  }
}
</style>
